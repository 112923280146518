<template>
  <b-card class="advanced-search" id="advanced-search">
    <div class="summary" id='summary'>
      {{ summary }}
    </div>

    <div class="controls">
      <b-form-radio-group
        v-model="typeProxy"
        size="sm" buttons button-variant="on-off"
        :options="types"
        aria-labelledby="summary"
      />
    </div>

    <div class="groups">
      <div class="group" v-for="(group, groupIndex) of groups" :key="'g' + groupIndex">
        <div class="controls">
          <b-form-radio-group
            v-model="group.type"
            size="sm" buttons button-variant="on-off"
            :options="types"
            aria-labelledby="summary"
          />

          <b-button-group>
            <b-btn v-if="groups.length > 1" size="sm" variant="danger" @click="deleteGroup(groupIndex)">
              <la-trash /> {{$INSIGHT('SEARCH').TEXT.GROUP.REMOVE}}
            </b-btn>
          </b-button-group>
        </div>

        <div class="conditions">
          <div class="field" v-for="(field, fieldIndex) of group.conditions" :key="'g' + groupIndex + 'f' + fieldIndex">
            <b-btn
              v-if="groups[groupIndex].conditions.length > 1"
              v-b-tooltip:advanced-search.html="`${$LOCAL('ToolTipLabelDelete')} condition`"
              class="delete" variant="danger" size="sm"
              @click="deleteCondition(groupIndex, fieldIndex)"
              :aria-label="`${$LOCAL('AriaLabelDelete')} condition ${fieldIndex + 1} of group ${groupIndex + 1}`"
            ><la-trash/></b-btn>

            <div class="column">
              <b-select
                v-model="field.field"
                size="sm"
                :options="columnOptions"
                @change="changeSelection(0, field)"
                :aria-label="$LOCAL('AriaLabelField')"
              >
                <template v-slot:first>
                  <option
                    v-if="[null, ''].indexOf(field.field) !== -1"
                    disabled hidden
                    :value="field.field"
                  >{{$INSIGHT('SEARCH').TEXT.FIELD.PLACEHOLDER}}</option>
                </template>
              </b-select>
            </div>

            <div class="operator">
              <b-select size="sm"
                v-model="field.operator_type"
                :placeholder="$INSIGHT('SEARCH').TEXT.OPERATOR.PLACEHOLDER"
                :options="columnOptions[field.field] ? columnOptions[field.field].operators : []"
                @change="changeSelection(1, field)"
                :aria-label="$LOCAL('AriaLabelOperator')"
              >
                <template v-slot:first>
                  <option
                    v-if="[null, ''].indexOf(field.field) !== -1"
                    disabled hidden
                    :value="null"
                  >{{$INSIGHT('SEARCH').TEXT.OPERATOR.PLACEHOLDER}}</option>
                  <option
                    v-else-if="field.operator_type === null"
                    disabled hidden
                    :value="null"
                  >{{$INSIGHT('SEARCH').TEXT.OPERATOR.SELECT_TEXT}}</option>
                </template>
              </b-select>
            </div>

            <!-- Explicitly not null as 0 is falsy but is allowed -->
            <div class="value">
              <span v-if="field.operator_type === null || operatorType(field) === $INSIGHT('SEARCH').OPERATOR_TYPES.BLANK"/>

              <dual-input size="sm"
                v-else-if="operatorType(field) === $INSIGHT('SEARCH').OPERATOR_TYPES.DUAL"
                v-model="field.value"
                @input="counter++"
                :type="fieldType(columnOptions[field.field], true)"
                :choices="columnOptions[field.field].meta.choices"
              />

              <span v-else-if="field.field_type === $INSIGHT('INPUT_TYPES').DATALIST">
                <b-input size="sm" list="datalist" v-model="field.value" :aria-label="$LOCAL('AriaLabelDatalist')"/>
                <b-form-datalist id="datalist" :options="columnOptions[field.field].meta.choices || []"/>
              </span>

              <b-select size="sm"
                v-else-if="field.field_type === $INSIGHT('INPUT_TYPES').SELECT || field.field_type === $INSIGHT('INPUT_TYPES').RADIO"
                v-model="field.value" :placeholder="$INSIGHT('MISC').COMMON_PHRASE.SELECT_A_VALUE"
                :options="columnOptions[field.field].meta.choices || []"
                :aria-label="$LOCAL('AriaLabelSelect')"
              />
              <!-- value || [] is required as choices changes to null before fieldtype is updated -->

              <b-input size="sm"
                v-else-if="operatorType(field) === $INSIGHT('SEARCH').OPERATOR_TYPES.YEAR"
                v-model="field.value"
                type="number"
                :placeholder="$INSIGHT('MISC').COMMON_PHRASE.TYPE_A_YEAR"
                :aria-label="$LOCAL('AriaLabelYearInput')"
              />

              <b-select size="sm"
                v-else-if="operatorType(field) === $INSIGHT('SEARCH').OPERATOR_TYPES.MONTH"
                v-model="field.value"
                :options="listOfMonth"
                :aria-label="$LOCAL('AriaLabelMonthInput')"
              />

              <b-input
                size="sm"
                v-else-if="operatorType(field) === $INSIGHT('SEARCH').OPERATOR_TYPES.SINGLE"
                v-model="field.value"
                :type="fieldType(columnOptions[field.field], false)"
                :aria-label="$LOCAL('AriaLabelSingleInput')"
              />
            </div>
            <b-btn
              class="button-add"
              size="sm"
              variant="success"
              @click="addCondition(groupIndex)" v-if="groups[groupIndex].conditions.length === fieldIndex + 1"
              :aria-label="`${$LOCAL('AriaLabelNew')} new condition to group ${groupIndex + 1}`"
            >
              <la-plus />
            </b-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="add-group">
      <b-btn size="sm" variant="success" @click="addGroup">
        <la-plus /> Add Group
      </b-btn>
    </div>

    <div class="search">
      <div class="spacer"></div>
      <b-btn variant="primary" @click="$emit('search')" :disabled="isValid === false">
        {{ buttonName }}
      </b-btn>
    </div>
  </b-card>
</template>

<script>
import Vue from 'vue'
import LaPlus from '@/assets/la-plus.svg'
import LaTrash from '@/assets/la-trash.svg'
import DualInput from '../DualInput'

import INSIGHT from '@/constants/INSIGHT.json'

export default {
  name: 'advanced-search',
  components: {
    LaPlus,
    LaTrash,
    DualInput
  },
  props: {
    columns: {
      type: [Object, Array, Boolean],
      default: () => ({})
    },
    type: {
      // Cannot find a way to use Constants,
      // Unable to get reference to Vue (in tests)
      // Seems to work okay in deployment,
      // but unable to be mocked/used in testing
      default: INSIGHT.SEARCH.GROUP_OPERATORS.AND
    },
    value: {
      default: () => ([])
    },
    buttonName: {
      type: String,
      default: 'Search'
    }
  },
  data: () => ({
    types: [
      { value: Vue.prototype.$INSIGHT('SEARCH').GROUP_OPERATORS.AND, text: 'AND' },
      { value: Vue.prototype.$INSIGHT('SEARCH').GROUP_OPERATORS.OR, text: 'OR' }
    ],
    counter: 0,
    saving: false,
    listOfMonth: [
      { value: 1, text: Vue.prototype.$INSIGHT('MISC').MONTH.JANUARY },
      { value: 2, text: Vue.prototype.$INSIGHT('MISC').MONTH.FEBRUARY },
      { value: 3, text: Vue.prototype.$INSIGHT('MISC').MONTH.MARCH },
      { value: 4, text: Vue.prototype.$INSIGHT('MISC').MONTH.APRIL },
      { value: 5, text: Vue.prototype.$INSIGHT('MISC').MONTH.MAY },
      { value: 6, text: Vue.prototype.$INSIGHT('MISC').MONTH.JUNE },
      { value: 7, text: Vue.prototype.$INSIGHT('MISC').MONTH.JULY },
      { value: 8, text: Vue.prototype.$INSIGHT('MISC').MONTH.AUGUST },
      { value: 9, text: Vue.prototype.$INSIGHT('MISC').MONTH.SEPTEMBER },
      { value: 10, text: Vue.prototype.$INSIGHT('MISC').MONTH.OCTOBER },
      { value: 11, text: Vue.prototype.$INSIGHT('MISC').MONTH.NOVEMBER },
      { value: 12, text: Vue.prototype.$INSIGHT('MISC').MONTH.DECEMBER }
    ]
  }),
  computed: {
    groups: {
      get () { return this.value },
      set (value) { this.$emit('update:input', value) }
    },
    typeProxy: {
      get () { return this.type },
      set (value) { this.$emit('update:type', value) }
    },
    columnOptions () { return this.columns },
    summary () {
      if (this.columnOptions === false) return ''
      if (this.groups.length === 0) return this.$INSIGHT('SEARCH').TEXT.SUMMARY.CONDITION_EMPTY
      let message = this.counter // just here to force a re-evaluation of the computed property as vue doesn't always tirgger when new arrays are added
      message = []
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const group of this.groups) {
        const conditions = []
        // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
        // eslint-disable-next-line no-unused-vars
        for (const field of group.conditions) {
          if (this.groups.length === 1 && field.field === '') break

          const column = this.columnOptions[field.field]
          if (column === undefined) break

          const operator = column.operators.find(o => o.value === field.operator_type)
          if (operator === undefined) break

          if (field.value !== '') {
            let value = typeof field.value === 'object' ? field.value[0] + ' and ' + field.value[1] : field.value
            if (operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.MONTH) {
              if (typeof field.value !== 'number') field.value = 1
              value = this.listOfMonth[field.value - 1].text
            } else if (field.field_type === this.$INSIGHT('INPUT_TYPES').RADIO) {
              value = field.value === -1 ? 'Yes' : 'No'
            }
            conditions.push('"' + column.text + '" ' + operator.text + ' "' + value + '"')
          }
        }

        message.push(conditions.join(' ' + this.types.find(t => t.value === group.type).text + ' '))
      }

      if (message.length > 1) return `${this.$INSIGHT('SEARCH').TEXT.SUMMARY.TITLE} ` + '(' + message.join(') ' + this.types.find(t => t.value === this.type).text + ' (') + ')'
      return `${this.$INSIGHT('SEARCH').TEXT.SUMMARY.TITLE} ` + message.join('')
    },
    isValid () {
      let i = this.counter // just here to force a re-evaluation of the computed property as vue doesn't always tirgger when new arrays are added
      i = 0
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const group of this.groups) {
        // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
        // eslint-disable-next-line no-unused-vars
        for (const field of group.conditions) {
          const column = this.columnOptions[field.field]
          if (column === undefined) return false

          const operator = column.operators.find(o => o.value === field.operator_type)
          if (operator === undefined) return false

          if (
            (operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.SINGLE || operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.MONTH) &&
            ['', null].indexOf(field.value) === -1
          ) i++
          else if (
            operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.DUAL &&
            typeof field.value === 'object' &&
            ['', null].indexOf(field.value[0]) === -1 &&
            ['', null].indexOf(field.value[1]) === -1
          ) i++
          else if (operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.BLANK) i++
          else if (operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.YEAR && field.value > 999 && field.value < 10000) i++
          else return false
        }
      }

      return i > 0
    }
  },
  methods: {
    operatorType (field) {
      if (['', undefined, null].indexOf(field.field) !== -1 || !this.columnOptions[field.field]) return null
      const operators = this.columnOptions[field.field].operators
      const operator = operators.find(op => op.value === field.operator_type)
      return operator ? operator.type : null
    },
    fieldType (field, allowSelect) {
      // valid input types
      if (field.type === this.$INSIGHT('INPUT_TYPES').DATETIME) {
        return this.$INSIGHT('INPUT_TYPES').DATE
      }

      if (
        [this.$INSIGHT('INPUT_TYPES').DATE, this.$INSIGHT('INPUT_TYPES').TIME, this.$INSIGHT('INPUT_TYPES').NUMBER].includes(field.type) ||
        // dual-input allows select, but b-input does not
        (allowSelect && field.type === this.$INSIGHT('INPUT_TYPES').SELECT)
      ) return field.type
      // if invalid, text
      return this.$INSIGHT('INPUT_TYPES').TEXT
    },
    changeSelection (selection, field) {
      if (!(selection || field)) return
      field.value = ''
      if (selection === 0) {
        field.operator_type = null
        field.field_type = this.columnOptions[field.field].type
      }
    },
    addGroup () {
      this.groups.push({
        type: this.$INSIGHT('SEARCH').GROUP_OPERATORS.AND,
        conditions: [
          {
            field: '',
            field_type: '',
            operator_type: null,
            value: ''
          }
        ]
      })
    },
    deleteGroup (group) {
      if (this.groups.length === 1) return
      this.groups.splice(group, 1)
    },
    addCondition (group) {
      this.groups[group].conditions.push({
        field: '',
        field_type: '',
        operator_type: null,
        value: ''
      })
    },
    deleteCondition (group, field) {
      if (this.groups[group].conditions.length === 1) return
      this.groups[group].conditions.splice(field, 1)
    }
    // saveAsQuery () {}, // TODO
  }
}
</script>

<style lang="scss" scoped>
.advanced-search {
  text-align: left;

  .summary {
    text-align: left;
  }

  .controls {
    display: flex;
    justify-content: space-between;
  }

  .add-group {
    text-align: right;
    margin: 1rem 0;
  }

  svg {
    width: 1em;
    height: auto;

    path {
      fill: #fff;
    }
  }

  .round {
    border: 1px solid #d3d3d3;
    border-radius: 100%;
    padding: 6px;
    height: auto;
    line-height: 1;
    flex-grow: 0;
    cursor: pointer;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }

  .groups {
    border: 1px solid #d3d3d3;
    padding: 15px;
  }

  .group {
    border: 1px solid #d3d3d3;
    padding: 0;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .controls {
      margin: 10px 15px;
    }
  }

  .conditions {
    .field {
      display: flex;
      padding: 10px 15px;
      margin: 5px 0;
      justify-content: space-between;
      align-items: center;
      border-top: 1px dashed #d3d3d3;

      &:last-of-type {
      margin-bottom: 0;

      .button-add {
        margin: 0px 0px 0px 15px;
      }
    }

      .column {
        flex-grow: 0;
        margin-right: 15px;
      }

      .operator {
        flex-grow: 0;
        margin-right: 15px;
      }

      .value {
        flex-grow: 1;
      }

      .delete {
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 15px;
      }
    }
  }

  .search {
    display: flex;
    justify-content: space-between;
    justify-items: flex-end;
    align-items: center;
    margin-top: 1rem;

    .spacer {
      flex-grow: 1;
    }
  }
}
</style>
