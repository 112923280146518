/*
  This CURRENCY_FORMAT sets options to format currency with below rules:
  (1) prefix with $
  (2) separate with comma for thousands
  (3) min 2dp and max 4dp. Max 4dp as this is the max dp in database for currency field
  Notes: Refer to Intl.NumberFormat for more info on the options used.

  Using CURRENCY_FORMAT will get sample outputs like below:
  e.g. 12345 -> $12,345.00 (trailing 0s to maintain min 2dp)
  e.g. 12345.6 -> $12,345.60 (trailing 0 to maintain min 2dp)
  e.g. 12345.67 -> $12,345.67
  e.g. 12345.678 -> $12,345.678
  e.g. 12345.6789 -> $12,345.6789
  e.g. 12345.67889 -> $12,345.6789 (will be rounded up to maintain 4dp)

  Above will have same result for negative values as well, just that negative values are prefix
  with a '-' symbol e.g. -$12,345.6789
 */
export const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 4,
  minimumFractionDigits: 2
})

/*
  Method to convert currency to a formatted string for display purposes
 */
export default {
  methods: {
    currencyString (value) {
      if (['', null, undefined].indexOf(value) !== -1) {
        return value
      }

      const currencyString = CURRENCY_FORMAT.format(value)
      // To return formatted value, or the original value if not a number and unable to format
      return currencyString.includes('NaN') ? value : currencyString
    }
  }
}
