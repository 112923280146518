<template>
  <div class="dual-input">
    <b-form-select size="sm"
      v-if="normalCaseInput1 && type === $INSIGHT('INPUT_TYPES').SELECT"
      v-model="input1"
      :options="choices"
      :placeholder="$INSIGHT('MISC').COMMON_PHRASE.SELECT_A_VALUE"
      :aria-label="$LOCAL('AriaLabelDualInputLower')"
    />
    <b-input
      size="sm"
      v-else-if="normalCaseInput1"
      v-model="input1" :type="type"
      :disabled="disableInput1"
      :aria-label="$LOCAL('AriaLabelDualInputLower')"/>
    <b-input
      size="sm"
      v-else
      v-model="input1"
      type="text"
      :disabled="disableInput1"
      :aria-label="$LOCAL('AriaLabelDualInputLower')"/>

    <div class="middle">
      and
    </div>

    <b-form-select
      size="sm"
      v-if="normalCaseInput2 && type === $INSIGHT('INPUT_TYPES').SELECT"
      v-model="input2"
      :options="choices"
      :placeholder="$INSIGHT('MISC').COMMON_PHRASE.SELECT_A_VALUE"
      :aria-label="$LOCAL('AriaLabelDualInputUpper')"
    />

    <b-input
      size="sm"
      v-else-if="normalCaseInput2"
      v-model="input2"
      :type="type"
      :disabled="disableInput2"
      :aria-label="$LOCAL('AriaLabelDualInputUpper')"/>
    <b-input
      size="sm"
      v-else v-model="input2"
      type="text"
      :disabled="disableInput2"
      :aria-label="$LOCAL('AriaLabelDualInputUpper')"/>
  </div>
</template>

<script>
export default {
  name: 'DualInput',
  props: {
    value: {
      default: () => ([])
    },
    value1: {
      default: null
    },
    value2: {
      default: null
    },
    type: {
      default: 'text'
    },
    size: {
      default: 'md'
    },
    disableInput1: {
      default: false
    },
    disableInput2: {
      default: false
    },
    choices: {
      default: null
    }
  },
  computed: {
    input1: {
      get () {
        if (this.value1 !== null || this.value2 !== null) {
          return this.value1
        } else {
          // Array is type Object (cannot use typeOf, null is typeOf object)
          return (this.value instanceof Array) ? this.value[0] : null
        }
      },
      set (value) {
        this.handleInput(0, value)
      }
    },
    input2: {
      get () {
        if (this.value2 !== null) {
          return this.value2
        } else {
          return (this.value instanceof Array) ? this.value[1] : null
        }
      },
      set (value) {
        this.handleInput(1, value)
      }
    },
    normalCaseInput1 () {
      return !(typeof this.input1 === 'string' && this.input1.startsWith('@#'))
    },
    normalCaseInput2 () {
      return !(typeof this.input2 === 'string' && this.input2.startsWith('@#'))
    }
  },
  methods: {
    handleInput (input, value) {
      if (this.value2 !== null) {
        if (input === 0) {
          this.$emit('input1', value)
        } else {
          this.$emit('input2', value)
        }
        this.$emit('input', value)
      } else {
        let output = this.value
        if (!(this.value instanceof Array)) {
          output = (input === 0) ? [value, null] : [null, value]
        } else {
          output[input] = value
        }
        this.$emit('input', output)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .dual-input {
    display: flex;

    .form-control, .custom-select {
      &:first-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .middle {
      display: flex;
      align-items: center;
      background: #e9ecef;
      color: #495057;
      padding: 0 10px;
      border-top: 1px solid #ced4da;
      border-bottom: 1px solid #ced4da;
    }
  }
</style>
