<template>
  <div id="search-display-component">
    <div v-if="displayDefaultText">
      {{ $INSIGHT('INDEX_GENERIC').TEXT.DEFAULT_SEARCH_NOTE_1 }} {{ hasAdvancedSearch ? $INSIGHT('INDEX_GENERIC').TEXT.DEFAULT_SEARCH_NOTE_2 : '' }}
    </div>
    <div v-else>
      {{$LOCAL('COMMON_WORD').DISPLAYING}}
      <b-overlay
        class="search-overlay d-inline-block"
        :show="loadingStatus"
        opacity="1"
        spinner-small
        spinner-variant="secondary"
        spinner-type="grow"
      >
        <strong>{{ totalCount }} {{ noun }}</strong>
        <span v-if="searchTerm">
          {{$LOCAL('COMMON_WORD').FOR}}: <strong>{{ searchTerm }}</strong>
        </span>
      </b-overlay>
    </div>
  </div>
</template>

<script>
// Use this component to handle the display text that comes with a search component
export default {
  name: 'search-display',
  props: {
    loadingStatus: {
      type: Boolean,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    },
    searchTerm: {
      type: String,
      required: true
    },
    modelName: { // e.g. Charts, Queries, Forms
      type: String,
      required: true
    },
    hasAdvancedSearch: {
      type: Boolean,
      required: true
    },
    displayDefaultText: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    noun () {
      return this.searchTerm ? this.$LOCAL('COMMON_WORD').RESULTS : this.modelName
    }
  }
}
</script>

<style lang="scss" scoped>
.search-overlay::after { // ensures that the overlay fully covers inner italicised text
  padding-right: 3px;
  content: '';
}
</style>
