<template>
  <div :class="['search-and-sort-controls', expandSearchBar? 'expanded-search-bar' : '']">
    <div v-data-cy="'search-and-sort'" class="search-and-sort">
      <b-form v-data-cy="'search'" v-on:submit.prevent="searchNow" class="search-form" inline>
        <b-input-group v-if="hasSearchBar">
          <template v-slot:prepend v-if="hasModelSelection">
            <b-form-select
              v-model="selectedModel"
              class="select-model shadow-none"
              @change="changeModel"
            >
              <option
                v-for="(model, modelId) of grantedModels"
                :key="modelId"
                :value="modelId"
              >
                {{ model.label || model.text }}
              </option>
            </b-form-select>
          </template>

          <b-input
            v-model="searchInput"
            class="shadow-none"
            placeholder="Search"
            ref="searchTextInput"
            trim
          />
          <span
            v-if="hasClearSearchCross"
            v-bind:style="{ display: clearSearchCrossDisplayed }"
            class="img-btn delete-button"
            @click="clearInput"
          >
            <LaTimes />
          </span>

          <template v-slot:append>
            <b-button
              id="search-button"
              type="submit"
              variant="primary"
              :aria-label="$LOCAL('AriaLabelSearch')"
            >
              <b-spinner v-if="loading" class="search-icon" />
              <span v-else><SearchIcon class="search-icon"/></span>
            </b-button>
          </template>
        </b-input-group>

        <b-button
          v-if="hasAdvancedSearch"
          v-model="isAdvancedOpened"
          class="advanced-search-toggle"
          :variant="advancedSearchVariant"
          @click="toggleAdvanced"
        >
          Advanced Search
        </b-button>

        <!-- can be optionally implemented -->
        <slot name="additional-filter-button-toggle" />
      </b-form>

      <div v-data-cy="'sort-and-refresh'" class="sort-and-refresh" v-if="showSortAndRefresh">
        <button
          v-data-cy="'refresh-btn'"
          class="mr-1"
          :class="{ refresh: true, loading, 'button-div': true }"
          :aria-label="$LOCAL('AriaLabelRefresh')"
          @click="refresh"
        >
          <LaRefresh class="refresh-button" />
        </button>
        <sort-selector
          v-data-cy="'sort-btn'"
          :fields="sortFields"
          :customIdField="sortCustomIdField"
          :customDateTimeField="sortCustomDateTimeField"
          :isForEntityOrLink="isForEntityOrLink"
          :initSortText="initialSortText"
          :defaultSortValues="defaultSortValues"
          @sortBy="handleSort"
          @latestSortText="handleLatestSortText"
        />
      </div>
    </div>

    <advanced-search
      v-data-cy="'advanced-search'"
      class="advanced-search"
      v-show="isAdvancedOpened"
      v-model="advancedSearch.groups"
      :columns="advancedSearchFields"
      :type.sync="advancedSearch.type"
      @search="searchNow"
    />

    <!-- can be optionally implemented together with additional-filter-button-toggle -->
    <slot v-data-cy="'additional-filter'" name="additional-filter" />

    <search-display
      v-data-cy="'search-display'"
      class="search-display font-italic my-2 text-left fade-in-fast"
      v-if="isShowingSearchMessage || isForIndexedSearch"
      :loadingStatus="loading"
      :totalCount="searchCount"
      :searchTerm="currentSentSearch"
      :modelName="searchNoun"
      :hasAdvancedSearch="hasAdvancedSearch"
      :displayDefaultText="shouldDisplayDefaultText"
    />
  </div>
</template>

<script>
import AdvancedSearch from '@/modules/insight/components/search_sort/AdvancedSearch'
import LaTimes from '@/assets/la-times.svg'
import LaRefresh from '@/assets/la-refresh.svg'
import SearchIcon from '@/assets/search-icon.svg'
import SortSelector from '@/modules/insight/components/search_sort/SortSelector'
import SearchDisplay from '@/modules/insight/components/search_sort/SearchDisplay'
import Vue from 'vue'

export default {
  name: 'search-sort-controls',
  components: {
    AdvancedSearch,
    LaTimes,
    LaRefresh,
    SearchIcon,
    SortSelector,
    SearchDisplay
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    showSortAndRefresh: {
      type: Boolean,
      required: true
    },
    hasSearchBar: {
      type: Boolean,
      default: true
    },
    hasClearSearchCross: {
      type: Boolean,
      required: true
    },
    hasAdvancedSearch: {
      type: Boolean,
      required: true
    },
    isShowingSearchMessage: {
      type: Boolean,
      default: true
    },
    isAdvancedOpened: {
      // Pass in only if hasAdvancedSearch is true
      type: Boolean,
      default: false
    },
    advancedSearchFields: {
      // Pass in only if hasAdvancedSearch is true
      type: Object,
      default: () => ({})
    },
    advancedSearch: {
      // Pass in only if hasAdvancedSearch is true
      type: Object,
      default: () => ({})
    },
    hasModelSelection: {
      type: Boolean,
      required: true
    },
    initialModel: {
      // Pass in only if hasModelSelection is true; To initialise this component's model with
      type: String,
      default: ''
    },
    models: {
      // Pass in only if hasModelSelection is true
      type: Object,
      default: () => ({})
    },
    currentSentSearch: {
      // To initialise this component's searchInput with, and also as the display result search term
      type: String,
      required: true
    },
    searchCount: {
      type: Number,
      required: true
    },
    searchNoun: {
      type: String,
      required: true
    },
    sortFields: {
      // To populate Sort dropdown
      type: Object,
      required: true
    },
    isForEntityOrLink: {
      type: Boolean,
      required: true
    },
    isForIndexedSearch: {
      type: Boolean,
      default: false
    },
    sortCustomIdField: {
      // Should be propped in only if isForEntityOrLink is false or 'unique_id' is not the Id of model
      type: String,
      default: () => Vue.prototype.$INSIGHT('SORT').DEFAULT_ID
    },
    sortCustomDateTimeField: {
      // Should be propped in only if isForEntityOrLink is false or passed in if 'create-date' is not the datetime field of model
      type: String,
      default: () => Vue.prototype.$INSIGHT('SORT').DEFAULT_DATETIME
    },
    initialSortText: {
      // Prop in only if you wish to maintain / initialise the displayed sortText of the previously selected sort field
      type: String,
      default: () => Vue.prototype.$INSIGHT('SORT').DEFAULT_SORTTEXT
    },
    defaultSortValues: { // Prop in only if you wish to replace default sort value
      type: Array,
      default: () => []
    },
    expandSearchBar: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedModel: null,
    searchInput: ''
  }),
  mounted () {
    this.$parent.$on('resetSearch', this.resetSearchInThisComponent) // registration of 'resetSearchInChild' event to listen from PARENT
    this.selectedModel = this.initialModel
    this.searchInput = this.currentSentSearch
  },
  computed: {
    advancedSearchVariant () {
      return this.isAdvancedOpened ? 'success' : 'secondary'
    },
    clearSearchCrossDisplayed () {
      return this.searchInput === '' ? 'none' : 'unset'
    },
    grantedModels () {
      // returns models that users have permission to read
      const items = {}
      for (const itemKey in this.models) {
        const permissions = this.models[itemKey].permissions
        if (
          permissions &&
          !permissions.find(permission =>
            permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').READ)
          )
        ) {
          items[itemKey] = this.models[itemKey]
        }
      }
      return items
    },
    shouldDisplayDefaultText () {
      return (
        (this.isForEntityOrLink &&
        !this.isAdvancedOpened &&
        !this.currentSentSearch) || (this.isForIndexedSearch && !this.isShowingSearchMessage)
      )
    }
  },
  methods: {
    changeModel () {
      this.$emit('changeModel', this.selectedModel)
    },
    clearInput () {
      this.searchInput = ''
      this.$refs.searchTextInput.focus()
      this.$emit('search', this.searchInput)
    },
    toggleAdvanced () {
      this.$emit('toggleAdvanced')
    },
    searchNow () {
      this.$emit('search', this.searchInput)
    },
    refresh () {
      this.$emit('refresh')
    },
    resetSearchInThisComponent (searchValue) {
      this.searchInput = searchValue
    },
    handleSort (sortBy) {
      this.$emit('sort', sortBy)
    },
    handleLatestSortText (sortText) {
      // Triggered only when SortSelector is about to be destroyed
      // Most likely needed if you want to init this component with a sortText
      this.$emit('lastSelectedSortText', sortText)
    }
  },
  watch: {
    initialModel () {
      this.selectedModel = this.initialModel
    }
  }
}
</script>

<style lang="scss" scoped>
.search-and-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;

  .search-form {
    .input-group {
      .input-group-prepend {
        .select-model {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .search-icon {
        display: block;
        margin: auto;
        width: 20px;
        height: 20px;
        fill: #ffffff;
      }

      .delete-button {
        position: relative;
        margin: 7px 7px 7px -31px;

        svg {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }

  .sort-and-refresh {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .advanced-search-toggle {
    margin-left: 28px;
    display: flex;
    align-items: center;

    .label {
      padding-right: 15px;
    }
  }
}

.advanced-search {
  display: block;
  margin-bottom: 28px;
}

.button-div {
  background-color: inherit;
  border: none;
}

.search-display {
  font-size: 1.2em;
}

.expanded-search-bar{
  .form-inline{
    flex-grow: 1;
  }
  .input-group {
    flex-grow: 0.6;
  }
}

.refresh-button {
  transition: all 0.8s;
  height: 20px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
</style>
