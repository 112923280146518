<template>
  <div>
    <b-dropdown
      size="sm"
      variant="outline-secondary"
      :text="sortText"
      no-flip
      right
    >
      <b-dropdown-item value="default" @click.native="dropdownSortClick()">{{$LOCAL('COMMON_WORD').DEFAULT}}</b-dropdown-item>
      <b-dropdown-item
        v-for="(field, fieldName) of fields"
        :value="fieldName"
        :key="fieldName"
        @click.native="dropdownSortClick(fieldName, field.label)"
      >
        {{ field.label }}
        <component class="sort-icon" :is="sortIconStatus(field.label)"/>
      </b-dropdown-item>
    </b-dropdown>
    <cancel-icon
      class="img-btn ml-1"
      v-if="sortText !== this.$INSIGHT('SORT').DEFAULT_SORTTEXT"
      @click="dropdownSortClick()"
    />
  </div>
</template>

<script>
// TODO Test Refresh on parent
import Vue from 'vue'
import CancelIcon from '@/assets/la-times.svg'
import SortAscIcon from '@/assets/la-sort-asc.svg'
import SortDescIcon from '@/assets/la-sort-desc.svg'

export default {
  name: 'SortSelector',
  components: { CancelIcon, SortAscIcon, SortDescIcon },
  props: {
    fields: { // Object of objects
      type: Object,
      default: () => ({})
    },
    isForEntityOrLink: {
      type: Boolean,
      default: true
    },
    customIdField: { // Should be propped in only if isForEntityOrLink is false or 'unique_id' is not the Id of model
      type: String,
      default: () => (Vue.prototype.$INSIGHT('SORT').DEFAULT_ID)
    },
    customDateTimeField: { // Should be propped in only if isForEntityOrLink is false or passed in if 'create-date' is not the datetime field of model
      type: String,
      default: () => (Vue.prototype.$INSIGHT('SORT').DEFAULT_DATETIME)
    },
    initSortText: { // Prop in only if you wish to maintain / initialise the displayed sortText of the previously selected sort field
      type: String,
      default: () => (Vue.prototype.$INSIGHT('SORT').DEFAULT_SORTTEXT)
    },
    defaultSortValues: { // Prop in only if you wish to replace default sort value
      type: Array,
      default: () => []
    }
  },
  mounted () {
    this.sortText = this.initSortText
  },
  beforeDestroy () {
    // emits the last set sortText to parent
    // Most likely needed if you want to init this component with a sortText,
    // and hence need to store lastSortText e.g. 'Title - ascending'.
    // NOTE: This event is NOT emitted upon every change, and hence parent must not rely
    // on this EVENT (latestSortText) to track current sortText
    this.$emit('latestSortText', this.sortText)
  },
  data: () => ({
    sortText: Vue.prototype.$INSIGHT('SORT').DEFAULT_SORTTEXT,
    asc: ' - ascending',
    desc: ' - descending'
  }),
  computed: {
    idField () {
      return this.isForEntityOrLink ? this.$INSIGHT('SORT').DEFAULT_ID : this.customIdField
    },
    createDateDescField () {
      return this.isForEntityOrLink ? `-${this.$INSIGHT('SORT').DEFAULT_DATETIME}` : `-${this.customDateTimeField}`
    }
  },
  methods: {
    sortIconStatus (fieldText) {
      // If it is ascending, change to descending, otherwise ascending
      return this.sortText === `${fieldText}${this.asc}` ? SortDescIcon : SortAscIcon
    },
    dropdownSortClick (fieldName, fieldText) {
      if (fieldName) {
        if (this.sortText === `${fieldText}${this.asc}`) {
          this.$emit('sortBy', [`-${fieldName}`, this.idField])
          this.sortText = `${fieldText}${this.desc}`
        } else {
          this.$emit('sortBy', [fieldName, this.idField])
          this.sortText = `${fieldText}${this.asc}`
        }
      } else {
        this.$emit('sortBy', this.defaultSortValues.length < 1
          ? [this.createDateDescField, this.idField] : this.defaultSortValues)
        this.sortText = this.$INSIGHT('SORT').DEFAULT_SORTTEXT
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sort-icon {
  height: 16px;
  width: 16px;
}
</style>
